<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pull-right">
        <b-button @click="add" class="btn btn-success">{{ $t("common.new") }}</b-button>
      </div>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>
            Дата с
          </th>
          <th>
            Дата по
          </th>
          <th>
            Причина
          </th>
          <th>
            
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ UtilsService.formatDate(item.dateFrom) }}</td>
          <td>{{ UtilsService.formatDate(item.dateTo) }}</td>
          <td>{{ item.reason }}</td>
          <td>
            <button
                type="button"
                @click="editItem(item)"
                class="btn btn-icon btn-success"
            >
              <i class="fa fa-pen"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>

      <b-modal ref="StudentExemptionFromPhysicalEducationAdd-modal">
        <template v-slot:modal-title>
          {{ $t("common.new") }}
        </template>
        <b-form>
          <b-form-group>
            <template v-slot:label>
              С
            </template>
            <b-form-datepicker
                v-model="form.dateFrom"
                locale="ru"
                start-weekday="1"
                :state="modelState.dateFrom"
            ></b-form-datepicker>
            <div class="invalid-feedback">{{ modelSaveError.dateFrom }}</div>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              По
            </template>
            <b-form-datepicker
                v-model="form.dateTo"
                locale="ru"
                start-weekday="1"
                :state="modelState.dateTo"
            ></b-form-datepicker>
            <div class="invalid-feedback">{{ modelSaveError.dateTo }}</div>
          </b-form-group>
          <b-form-group >
            <template v-slot:label>
              Причина
            </template>
            <b-form-input
                v-model="form.reason"
                :state="modelState.whatsAppNumber"
                required
            ></b-form-input>
            <div class="invalid-feedback">{{ modelSaveError.whatsAppNumber }}</div>
          </b-form-group>
        </b-form>
        <template v-slot:modal-footer>
          <b-button type="submit" @click="onSubmit" variant="primary">{{
              $t("common.save")
            }}
          </b-button>
          <b-button type="reset" @click="onReset" variant="danger">{{
              $t("common.cancel")
            }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<style>

</style>
<script>
import ApiService from "@/common/api.service";
import UtilsService from "@/common/utils.service";

const MODAL_NAME = "StudentExemptionFromPhysicalEducationAdd-modal";
export default {
  name: "StudentExemptionFromPhysicalEducation",
  components: {},
  props: {
    studentId: String,
  },
  data() {
    return {
      items: [],
      form: {
        dateFrom: null,
        dateTo: null,
        reason: null
      },
      modelState:{},
      modelSaveError:{},
     
    };
  },
  mounted() {
    this.reloadData();
  },
  setup() {
    return { UtilsService }
  },
  methods: {
    reloadData() {
      let $this = this;
      let route = 'students/getStudentExemptionFromPhysicalEducation';
      let params = {studentId: this.studentId};

      ApiService.querySecured(route, {
        params: params
      }).then(({data}) => {
        $this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    add() {
      this.form = {};
      this.$refs[MODAL_NAME].show();
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.studentToGroupId = parseInt(this.studentId);

      this.modelState = {};
      this.modelSaveError = {};

      ApiService.postSecured("students/saveStudentExemptionFromPhysicalEducation", this.form)
          .then(function () {
            $this.$refs[MODAL_NAME].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[MODAL_NAME].hide();
    },
    editItem(item){
      this.form = item;
      this.$refs[MODAL_NAME].show();
    },
  }
};
</script>
