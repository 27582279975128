<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentInfo.title") }}
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="regeneratePassword" v-if="showEditButton">
              Перегенерировать пароль
            </button>
            <button class="btn btn-icon btn-success" @click="editStudentData" v-if="showEditButton" style="margin-left: 10px">
              <i class="fa fa-pen"></i>
            </button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <tbody>
              <tr>
                <td>{{ $t("studentInfo.data.inn") }}</td><td>{{student.inn}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.firstName") }}</td><td>{{student.firstName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.name") }}</td><td>{{student.name}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.patronymic") }}</td><td>{{student.patronymic}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.birthDate") }}</td><td>{{student.birthDateValue}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.sex") }}</td><td>{{showSex(student.sex)}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.birthCertificateNumber") }}</td><td>{{student.birthCertificateNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.citizenshipId") }}</td><td>{{student.citizenshipName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.nationalityId") }}</td><td>{{student.nationalityName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.livingAddress") }}</td><td>{{student.livingAddress}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.phoneNumber") }}</td><td>{{student.phoneNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.whatsApp") }}</td><td>{{student.whatsApp}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.cartNumber") }}</td><td>{{student.cartNumber}}</td>
              </tr>
<!--              <tr>
                <td>{{ $t("studentInfo.data.livingRegionId") }}</td><td>{{student.livingRegionId}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.registeredInPolyclinic") }}</td><td>{{student.registeredInPolyclinic}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.onLineEducation") }}</td><td>{{student.onLineEducation}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.socialPassportCategoryId") }}</td><td>{{student.socialPassportCategoryId}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.schoolEnterYear") }}</td><td>{{student.schoolEnterYear}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.certificateNumber") }}</td><td>{{student.certificateNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.hasAward") }}</td><td>{{student.hasAward}}</td>
              </tr>

              <tr>
                <td>{{ $t("studentInfo.data.nativeLanguageId") }}</td><td>{{student.nativeLanguageId}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.enterDate") }}</td><td>{{student.enterDate}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.enterGroupName") }}</td><td>{{student.enterGroupName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.retirementDate") }}</td><td>{{student.retirementDate}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.retirementGroupName") }}</td><td>{{student.retirementGroupName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.orderNumber") }}</td><td>{{student.orderNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.orderDate") }}</td><td>{{student.orderDate}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.retirementTarget") }}</td><td>{{student.retirementTarget}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.retirementReason") }}</td><td>{{student.retirementReason}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.cartIssueNote") }}</td><td>{{student.cartIssueNote}}</td>
              </tr>-->
              <tr>
                <td>{{ $t("studentInfo.data.socialPassportCategoryId") }}</td>
                <td>
                  <button class="btn btn-icon btn-success" @click="addSocialCategory" v-if="showEditButton">
                    <i class="fa fa-pen"></i>
                  </button>
                  <table>
                    <tbody>
                    <tr v-for="category in studentSocialCategories" :key="category.id">
                      <td>{{category.name}}</td>
                      <td>
                        <div v-for="(attr, k) in category.attributes" :key="k">
                          <b>{{attr.title}}</b> - <i>{{attr.value}}</i>
                        </div>
                      </td>
                      <td>
                        <button class="btn btn-icon btn-danger" @click="deleteSocialCategory(category.id)" v-if="showEditButton">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>         
            <div>
              <button class="btn btn-success" @click="transferStudent" 
                      v-if="student.isAvailableForTransfer">
                Перевести <i class="fa fa-arrow-right"></i> 
              </button>
              <button class="btn btn-success" @click="softTransferStudent" v-if="isAdmin()">
                Перекинуть в другой класс <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:title>
            Освобождение от физкультуры
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <StudentExemptionFromPhysicalEducation :student-id="studentId" ref="studentExemptionFromPhysicalEducation"></StudentExemptionFromPhysicalEducation>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentInfo.parents.title") }}
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="createParent" v-if="showEditButton">{{ $t("common.new") }}</button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>{{ $t("studentInfo.parents.list.fullName") }}</th>
                <th>{{ $t("studentInfo.parents.list.phoneNumber") }}</th>  
                <th>{{ $t("studentInfo.parents.list.whatsAppNumber") }}</th>  
                <th></th>
              </tr>              
              </thead>
              <tbody>
                <tr v-for="parent in parents" :key="parent.id">
                  <td>{{parent.name}}</td>
                  <td>{{parent.phoneNumber}}</td>
                  <td>{{parent.whatsAppNumber}}</td>
                  <td>
                    <button class="btn btn-icon btn-success" @click="editParent(parent)" v-if="showEditButton">
                      <i class="fa fa-pen"></i>
                    </button>
                    <button class="btn btn-icon btn-danger" @click="deleteParent(parent.id)" v-if="showEditButton">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:title>
            Прибытие
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-icon btn-success" @click="editArrivalStudentData" v-if="showEditButton">
              <i class="fa fa-pen"></i>
            </button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>Дата прибытия</th>
                <th>Причина прибытия</th>
                <th>Номер приказа</th>
                <th>Откуда прибыл</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{student.startDate}}</td>
                <td>{{student.transferReason}}</td>
                <td>{{student.transferOrderNumber}}</td>
                <td>{{student.transferNote}}</td>
                <td>
                  <button class="btn btn-icon btn-danger" @click="deleteArrival"
                          v-if="showEditButton && student.startDate !== null">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:title>
            Выбытие
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-icon btn-success" @click="dropStudent" v-if="showEditButton">
              <i class="fa fa-pen"></i>
            </button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>Дата выбатия</th>
                <th>Причина выбытия</th>
                <th>Номер приказа</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{student.endDate}}</td>
                <td>{{student.dropReason}}</td>
                <td>{{student.dropOrderNumber}}</td>
                <td>
                  <button class="btn btn-icon btn-danger" @click="deleteDrop" v-if="showEditButton && student.endDate !== null">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:title>
            Оценки
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-tabs pills card>
              <b-tab>
                <template v-slot:title>
                  I четверть
                </template>
                <StudentMarksByQuarter :student-id="studentId" :quarter="1" ref="studentMarksByQuarter1"></StudentMarksByQuarter>
              </b-tab>       
              <b-tab>
                <template v-slot:title>
                  II четверть
                </template>
                <StudentMarksByQuarter :student-id="studentId" :quarter="2" ref="studentMarksByQuarter2"></StudentMarksByQuarter>
              </b-tab>       
              <b-tab>
                <template v-slot:title>
                  III четверть
                </template>
                <StudentMarksByQuarter :student-id="studentId" :quarter="3" ref="studentMarksByQuarter3"></StudentMarksByQuarter>
              </b-tab>    
              <b-tab>
                <template v-slot:title>
                  IV четверть
                </template>
                <StudentMarksByQuarter :student-id="studentId" :quarter="4" ref="studentMarksByQuarter4"></StudentMarksByQuarter>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
    <b-modal id="parents-modal" ref="parents-modal">
      <template v-slot:modal-title>
        {{ $t("studentInfo.parents.title") }}
      </template>
      <b-form>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.inn") }}
          </template>
          <b-form-input
              v-model="parent.inn"
              :state="parentModelStates.inn"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.inn }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.firstName") }}
          </template>
          <b-form-input
              v-model="parent.firstName"
              :state="parentModelStates.firstName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.firstName }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.name")  }}
          </template>
          <b-form-input
              v-model="parent.name"
              :state="parentModelStates.name"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.name }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.patronymic")  }}
          </template>
          <b-form-input
              v-model="parent.patronymic"
              :state="parentModelStates.patronymic"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.patronymic }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.address")  }}
          </template>
          <b-form-input
              v-model="parent.address"
              :state="parentModelStates.address"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.address }}</div>
        </b-form-group> 
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.sex")  }}
          </template>
          <b-form-select
              v-model="parent.sex"
              :state="parentModelStates.sex"
              :options="sexOptions"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ parentModelSaveError.sex }}</div>
        </b-form-group> 
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.phoneNumber")  }}
          </template>
          <b-form-input
              v-model="parent.phoneNumber"
              :state="parentModelStates.phoneNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.phoneNumber }}</div>
        </b-form-group>
        <b-form-group >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.whatsAppNumber")  }}
          </template>
          <b-form-input
              v-model="parent.whatsAppNumber"
              :state="parentModelStates.whatsAppNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.whatsAppNumber }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workOrganizationName")  }}
          </template>
          <b-form-input
              v-model="parent.workOrganizationName"
              :state="parentModelStates.workOrganizationName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workOrganizationName }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workPosition")  }}
          </template>
          <b-form-input
              v-model="parent.workPosition"
              :state="parentModelStates.workPosition"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workPosition }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workAddress")  }}
          </template>
          <b-form-input
              v-model="parent.workAddress"
              :state="parentModelStates.workAddress"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workAddress }}</div>
        </b-form-group>

      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveParentData" variant="primary" id="parent-data-save-button">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeParentForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="student-modal" ref="student-modal">
      <template v-slot:modal-title>
        {{ $t("studentInfo.studentForm.title") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.inn") }}
          </template>
          <b-form-input
              v-model="student.inn"
              :state="studentModelStates.inn"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.inn }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.firstName") }}
          </template>
          <b-form-input
              v-model="student.firstName"
              :state="studentModelStates.firstName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.firstName }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.name") }}
          </template>
          <b-form-input
              v-model="student.name"
              :state="studentModelStates.name"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.name }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.patronymic") }}
          </template>
          <b-form-input
              v-model="student.patronymic"
              :state="studentModelStates.patronymic"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.patronymic }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.birthDate") }}
          </template>
          <b-form-datepicker
              v-model="student.birthDate"
              :state="studentModelStates.birthDate"
              locale="ru"
              start-weekday="1"
              required
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentModelSaveError.birthDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.sex") }}
          </template>
          <b-form-select
              v-model="student.sex"
              :state="studentModelStates.sex"
              :options="sexOptions"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentModelSaveError.sex }}</div>
        </b-form-group>  
<!--        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.socialPassportCategoryId") }}
          </template>
          <b-form-select
              v-model="student.socialPassportCategoryId"
              :state="studentModelStates.socialPassportCategoryId"
              :options="socialPassportCategories"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentModelSaveError.socialPassportCategoryId }}</div>
        </b-form-group>-->
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.birthCertificateNumber") }}
          </template>
          <b-form-input
              v-model="student.birthCertificateNumber"
              :state="studentModelStates.birthCertificateNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.birthCertificateNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.citizenshipId") }}
          </template>
          <b-form-select
              v-model="student.citizenshipId"
              :state="studentModelStates.citizenshipId"
              :options="citizenshipOption"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentModelSaveError.citizenshipId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.nationalityId") }}
          </template>
          <b-form-select
              v-model="student.nationalityId"
              :state="studentModelStates.nationalityId"
              :options="nationalityOption"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentModelSaveError.nationalityId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.livingAddress") }}
          </template>
          <b-form-input
              v-model="student.livingAddress"
              :state="studentModelStates.livingAddress"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.livingAddress }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.phoneNumber") }}
          </template>
          <b-form-input
              v-model="student.phoneNumber"
              :state="studentModelStates.phoneNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.phoneNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.whatsApp") }}
          </template>
          <b-form-input
              v-model="student.whatsApp"
              :state="studentModelStates.whatsApp"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.whatsApp }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.cartNumber") }}
          </template>
          <b-form-input
              v-model="student.cartNumber"
              :state="studentModelStates.cartNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.cartNumber }}</div>
        </b-form-group>
        
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveStudentIno" variant="primary" id="student-info-save-button">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeStudentForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="student-drop-modal" ref="student-drop-modal">
      <template v-slot:modal-title>
        {{ $t("studentsList.drop.title") }}
      </template>
      <b-form>
        <div class="alert alert-primary">
          {{ dropStudentName }}
        </div>
        <b-form-group>
          <template v-slot:label>
            Номер приказа
          </template>
          <b-form-input
              v-model="dropForm.dropOrderNumber"
              :state="studentDropModelStates.dropOrderNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentDropModelSaveError.dropOrderNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentsList.drop.form.date") }}
          </template>
          <b-form-datepicker
              v-model="dropForm.dropDate"
              :state="studentDropModelStates.dropDate"
              locale="ru"
              start-weekday="1"
              required
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentDropModelSaveError.dropDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentsList.drop.form.reason") }}
          </template>
          <b-form-select
              v-model="dropForm.dropReasonId"
              :options="dropReasons"
              :state="studentDropModelStates.dropReasonId"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentDropModelSaveError.dropReasonId }}</div>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveDropStudent" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeDropForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="student-arrival-modal" ref="student-arrival-modal">
      <template v-slot:modal-title>
        Прибытие
      </template>
      <b-form>
        <div class="alert alert-primary">
          {{ arrivalStudentName }}
        </div>
        <b-form-group>
          <template v-slot:label>
            Номер приказа
          </template>
          <b-form-input
              v-model="arrivalForm.transferOrderNumber"
              :state="studentArrivalModelStates.transferOrderNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentArrivalModelSaveError.transferOrderNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Дата прибытия
          </template>
          <b-form-datepicker
              v-model="arrivalForm.startDate"
              :state="studentArrivalModelStates.startDate"
              locale="ru"
              start-weekday="1"
              required
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentArrivalModelSaveError.startDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Причина прибытия
          </template>
          <b-form-select
              v-model="arrivalForm.transferReasonId"
              :options="transferReasons"
              :state="studentArrivalModelStates.transferReasonId"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentArrivalModelSaveError.transferReasonId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Откуда прибыл
          </template>
          <b-form-textarea
              v-model="arrivalForm.transferNote"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveArrivalStudent" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeArrivalForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="student-transfer-modal" ref="student-transfer-modal">
      <template v-slot:modal-title>
        Перевести
      </template>
      <b-form>
        <div class="alert alert-primary">
          {{ transferStudentName }}
        </div>
        <b-form-group id="input-group-1" label-for="input-1">
          <template v-slot:label>
            Номер приказа
          </template>
          <b-form-input
              id="teacherBd"
              v-model="transferForm.transferOrderNumber"
              :state="studentTransferModelStates.transferOrderNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferOrderNumber }}</div>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1">
          <template v-slot:label>
            Дата
          </template>
          <b-form-datepicker
              id="teacherBd"
              v-model="transferForm.transferDate"
              :state="studentTransferModelStates.transferDate"
              required
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferDate }}</div>
        </b-form-group>
        <b-form-group id="input-group-5" label-for="input-5">
          <template v-slot:label>
            Причина
          </template>
          <b-form-select
              v-model="transferForm.transferReasonId"
              :options="transferReasons"
              :state="studentTransferModelStates.transferReasonId"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferReasonId }}</div>
        </b-form-group> 
        <b-form-group id="input-group-5" label-for="input-5">
          <template v-slot:label>
            Класс
          </template>
          <b-form-select
              v-model="transferForm.groupId"
              :options="transferGroups"
              :state="studentTransferModelStates.groupId"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.groupId }}</div>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveTransferStudent" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeTransferForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="student-soft-transfer-modal" ref="student-soft-transfer-modal">
      <template v-slot:modal-title>
        Перевести, использовать только если по ошибке закинули в класс
      </template>
      <b-form>
        <div class="alert alert-primary">
          {{ transferStudentName }}
        </div>
        <b-form-group>
          <template v-slot:label>
            Класс
          </template>
          <b-form-select
              v-model="softTransferForm.groupId"
              :options="transferGroups"
              :state="softStudentTransferModelStates.groupId"
          ></b-form-select>
          <div class="invalid-feedback">{{ softStudentTransferModelSaveError.groupId }}</div>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveSoftTransferStudent" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeSoftTransferForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
    
    <b-modal id="student-social-cat-modal" ref="student-social-cat-modal">
      <template v-slot:modal-title>
        Социальная категория
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.socialPassportCategoryId") }}
          </template>
          <b-form-select
              @change="socialPassportCategoryChange"
              v-model="studentSocialCategoryForm.socialPassportCategoryId"
              :options="socialPassportCategories"
          ></b-form-select>
        </b-form-group> 
        <b-form-group v-if="studentSocialCategoryFormConfig.stringAttribute1">
          <template v-slot:label>
            {{ studentSocialCategoryFormConfig.stringAttribute1 }}
          </template>
            <b-form-textarea v-model="studentSocialCategoryForm.stringAttribute1"></b-form-textarea>
        </b-form-group> 
        <b-form-group v-if="studentSocialCategoryFormConfig.stringAttribute2">
          <template v-slot:label>
            {{ studentSocialCategoryFormConfig.stringAttribute2 }}
          </template>
            <b-form-textarea v-model="studentSocialCategoryForm.stringAttribute2"></b-form-textarea>
        </b-form-group>  
        <b-form-group v-if="studentSocialCategoryFormConfig.stringAttribute3">
          <template v-slot:label>
            {{ studentSocialCategoryFormConfig.stringAttribute3 }}
          </template>
            <b-form-textarea v-model="studentSocialCategoryForm.stringAttribute3"></b-form-textarea>
        </b-form-group>
        
        <b-form-group v-if="studentSocialCategoryFormConfig.numberAttribute1">
          <template v-slot:label>
            {{ studentSocialCategoryFormConfig.numberAttribute1 }}
          </template>
            <b-form-input v-model="studentSocialCategoryForm.numberAttribute1"></b-form-input>
        </b-form-group>    
        <b-form-group v-if="studentSocialCategoryFormConfig.numberAttribute2">
          <template v-slot:label>
            {{ studentSocialCategoryFormConfig.numberAttribute2 }}
          </template>
            <b-form-input v-model="studentSocialCategoryForm.numberAttribute2"></b-form-input>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveSocialCategory" variant="primary">{{
            $t("common.add")
          }}
        </b-button>
        <b-button type="reset" @click="closeSocialCategoryForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import StudentMarksByQuarter from "./studentInfo/partials/StudentMarksByQuarter";
import StudentExemptionFromPhysicalEducation from "./studentInfo/partials/StudentExemptionFromPhysicalEducation";
import {mapGetters} from "vuex";

export default {
  name: "studentInfo",
  components: {
    KTPortlet,
    StudentMarksByQuarter,
    StudentExemptionFromPhysicalEducation,
  },
  data() {
    return {
      studentId: this.$route.params.id,
      student: {},
      studentModelStates:{},
      studentModelSaveError: {},
      parents: [],
      parent:{},
      parentModelStates:{},
      parentModelSaveError: {},
      sexOptions:DictionariesService.sexOptions(),
      nationalityOption:[],
      citizenshipOption:[],
      dropReasons:[],
      dropForm:{
        dropDate:null,
        dropReasonId: null,
        dropOrderNumber: ''        
      }, 
      arrivalForm:{
        startDate:null,
        transferReasonId: null,
        transferOrderNumber: '',
        transferNote: ''
      },
      studentDropModelStates:{},
      studentDropModelSaveError:{},
      dropStudentName: '',
      
      transferReasons:[],
      transferGroups:[],
      transferForm:{
        groupId:null,
        transferDate:null,
        transferReasonId: null,
        transferOrderNumber: ''
      },
      softTransferForm:{
        groupId:null,
      },
      studentTransferModelStates:{},
      studentTransferModelSaveError:{},

      softStudentTransferModelStates:{},
      softStudentTransferModelSaveError:{},
      
      transferStudentName: '',

      studentArrivalModelStates:{},
      studentArrivalModelSaveError:{},
      arrivalStudentName: '',
      
      socialPassportCategories: [],
      studentSocialCategories: [],
      studentSocialCategoryForm: {
        socialPassportCategoryId: null
      },
      studentSocialCategoryFormConfig: {
        stringAttribute1:null,
        stringAttribute2:null,
        stringAttribute3:null,
        numberAttribute1:null,
        numberAttribute2:null,
      },
      studentSocialCategoryConfig: {},
      showEditButton: false
    };
  },
  mounted() {
    this.reloadData();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    reloadData() {
      let $this = this;
      
      ApiService.querySecured("students/info", {
        params: {studentToGroupId: this.$route.params.id}
      }).then(({data}) => {
        $this.student = data.student;
        $this.parents = data.parents;
      }).catch(({response}) => {
        console.log(response);
      });
      
      ApiService.querySecured("dictionaries/nationality").then(({data}) => {
        $this.nationalityOption = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });
      
      ApiService.querySecured("dictionaries/citizenship").then(({data}) => {
        $this.citizenshipOption = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/socialPassportCategories").then(({data}) => {
        this.socialPassportCategories = data.dictionary;
        this.studentSocialCategoryConfig = {};
        for (let i in this.socialPassportCategories){
          let item = this.socialPassportCategories[i];
          this.studentSocialCategoryConfig[item['value']] = item['config'];
        }
        
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/dropReasons").then(({data}) => {
        this.dropReasons = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });  
      
      ApiService.querySecured("dictionaries/transferReasons").then(({data}) => {
        this.transferReasons = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });
      
      ApiService.querySecured("dictionaries/groupsForTransfer").then(({data}) => {
        this.transferGroups = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("access/getUserAccessStudentData", {
        params: {studentId: this.$route.params.id}
      })
          .then(({data}) => {
            this.showEditButton = data.accessEditing;
          }).catch(({response}) => {
        console.log(response);
      });
      
      this.loadSocialCategory();

      this.$refs['studentExemptionFromPhysicalEducation'].reloadData();
    },
    /*parent*/
    createParent(){
      this.parent = {};
      this.$refs["parents-modal"].show();
    },
    editParent(item){
      let $this = this;
      ApiService.querySecured("students/findParent", {params: {id: item.id}})
          .then(({data}) => {
            $this.parent = data;
            $this.$refs["parents-modal"].show();
          }).catch(({response}) => {
        console.log(response);
      });
    },
    saveParentData(evt){
      let submitButton = document.getElementById('parent-data-save-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      evt.preventDefault();
      
      let $this = this;
      this.parentModelStates = {};
      this.parentModelSaveError = {};
      this.parent.studentId = this.$route.params.id;
      ApiService.postSecured("students/saveParent", this.parent)
          .then(function () {
            $this.$refs["parents-modal"].hide();
            $this.reloadData();
            $this.cleanParentModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.parentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.parentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
      
    },
    closeParentForm(){
      this.$refs["parents-modal"].hide();
      this.cleanParentModel();
    },
    cleanParentModel() {
      this.parent = {};
    },
    deleteParent(id) {
      if(!confirm("Удалить?")){
        return;
      }
      let $this = this;
      ApiService.postSecured("students/deleteParent", {id : id})
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });

    },
    /*student*/
    saveStudentIno(evt){
      let submitButton = document.getElementById('student-info-save-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      evt.preventDefault();

      let $this = this;
      this.studentModelStates = {};
      this.studentModelSaveError = {};
      ApiService.postSecured("students/saveStudentInfo", this.student)
          .then(function () {
            $this.$refs["student-modal"].hide();
            $this.reloadData();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.studentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });

    },
    editStudentData(){
      this.$refs["student-modal"].show();
    },
    closeStudentForm(){
      this.$refs["student-modal"].hide();
    },
    showSex(sexId){
      for (let i in this.sexOptions){
        let sex = this.sexOptions[i];
        
        if(sex.value == sexId){
          return sex.text;
        }       
      }
      
      return '';
    },
    
    /*drop*/
    dropStudent() {
      let student = this.student;
      this.dropForm = {
        studentToGroupId: student.id,
        dropDate: student.endDate,
        dropReasonId: student.dropReasonId,
        dropOrderNumber: student.dropOrderNumber,
      };
      this.dropStudentName = student.firstName + " " + student.name;
      this.$refs["student-drop-modal"].show();
    },
    saveDropStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/drop", this.dropForm)
          .then(function () {
            $this.$refs["student-drop-modal"].hide();

            $this.reloadData();
            $this.dropForm = {};
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.studentDropModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentDropModelStates[i] = false;
              }
            } else {
              $this.studentDropModelSaveError = response.data;

              for (let i in response.data) {

                $this.studentDropModelStates[i] = false;
              }
            }
          });
    },
    closeDropForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.dropForm = {};

      this.$refs["student-drop-modal"].hide();
    },
    /*enddrop*/
    /*arrival*/
    editArrivalStudentData() {
      let student = this.student;
      this.arrivalForm = {
        studentToGroupId: student.id,
        startDate: student.startDate,
        transferReasonId: student.transferReasonId,
        transferOrderNumber: student.transferOrderNumber,
        transferNote: student.transferNote,
      };
      this.arrivalStudentName = student.firstName + " " + student.name;
      this.$refs["student-arrival-modal"].show();
    },
    saveArrivalStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/arrival", this.arrivalForm)
          .then(function () {
            $this.$refs["student-arrival-modal"].hide();

            $this.reloadData();
            $this.arrivalForm = {};
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.studentArrivalModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentArrivalModelStates[i] = false;
              }
            } else {
              $this.studentArrivalModelSaveError = response.data;

              for (let i in response.data) {

                $this.studentArrivalModelStates[i] = false;
              }
            }
          });
    },
    closeArrivalForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.arrivalForm = {};

      this.$refs["student-arrival-modal"].hide();
    },
    /*endarrival*/


    /*transfer*/
    transferStudent() {
      let student = this.student;
      this.transferForm = {
        studentToGroupId: student.id,
      };
      this.transferStudentName = student.firstName + " " + student.name;
      this.$refs["student-transfer-modal"].show();
    },
    saveTransferStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/transfer", this.transferForm)
          .then(function () {
            $this.$refs["student-transfer-modal"].hide();

            $this.reloadData();
            $this.transferForm = {};
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.studentDropModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentTransferModelStates[i] = false;
              }
            } else {
              $this.studentDropModelSaveError = response.data;

              for (let i in response.data) {

                $this.studentTransferModelSaveError[i] = false;
              }
            }
          });
    },
    closeTransferForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.transferForm = {};

      this.$refs["student-transfer-modal"].hide();
    },
    /*enddrop*/
    /*soft transfer*/
    softTransferStudent() {
      let student = this.student;
      this.softTransferForm = {
        studentToGroupId: student.id,
      };
      this.transferStudentName = student.firstName + " " + student.name;
      this.$refs["student-soft-transfer-modal"].show();
    },
    saveSoftTransferStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/softTransferStudent", this.softTransferForm)
          .then(function () {
            $this.$refs["student-soft-transfer-modal"].hide();

            $this.reloadData();
            $this.softTransferForm = {};
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.softStudentTransferModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.softStudentTransferModelStates[i] = false;
              }
            } else {
              $this.softStudentTransferModelSaveError = response.data;

              for (let i in response.data) {

                $this.softStudentTransferModelSaveError[i] = false;
              }
            }
          });
    },
    closeSoftTransferForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.softTransferForm = {};

      this.$refs["student-soft-transfer-modal"].hide();
    },
    
    /*social category start*/
    
    loadSocialCategory(){
      
      let $this = this;
      ApiService.querySecured("students/listSocialPassportCategory", {
        params: {studentToGroupId: this.$route.params.id}
      }).then(({data}) => {
        $this.studentSocialCategories = data;
        for(let i in $this.studentSocialCategories){
          let item = $this.studentSocialCategories[i];
          
          let attributes = [];
          let attributeConfig = JSON.parse(item.attributeConfig);
          for (let ii in attributeConfig){
            if(attributeConfig[ii] !== null){
              attributes.push({
                "title": attributeConfig[ii],
                "value": item[ii]
              })
            }            
          }
          $this.studentSocialCategories[i].attributes = attributes;
        }
        
        
      }).catch(({response}) => {
        console.log(response);
      });
    },    
    addSocialCategory() {      
      this.studentSocialCategoryForm = {};

      this.$refs["student-social-cat-modal"].show();
    }, 
    saveSocialCategory() {
      this.studentSocialCategoryForm['studentToGroupId'] = parseInt(this.studentId);
      let $this = this;

      ApiService.postSecured("students/saveSocialPassportCategory", this.studentSocialCategoryForm)
          .then(function () {
            $this.loadSocialCategory();

            this.$refs["student-social-cat-modal"].hide();
          })
          .catch(({response}) => {
            console.log(response);

            this.$refs["student-social-cat-modal"].hide();
          });
    },  
    closeSocialCategoryForm() {      
      this.studentSocialCategoryForm = {};

      this.$refs["student-social-cat-modal"].hide();
    }, 
    deleteSocialCategory(id) {      
      if(!confirm("Удалить?")){
        return;
      }
      let $this = this;
      ApiService.postSecured("students/deleteSocialPassportCategory", {id : id})
          .then(function () {
            $this.loadSocialCategory();
          })
          .catch(({response}) => {
            console.log(response);
          });
      
    },
    socialPassportCategoryChange(){
      let catId = this.studentSocialCategoryForm.socialPassportCategoryId;
      this.studentSocialCategoryFormConfig = JSON.parse(this.studentSocialCategoryConfig[catId]);
    },
    /*social category end*/

    regeneratePassword() {
      let student = {
        id: this.studentId
      }
      if(!confirm("Изменить пароль?")){
        return;
      }
      ApiService.postSecured("students/regenerateStudentPassword", student)
          .then(function () {
            alert('Пароль изменен');
          })
          .catch(() => {
            alert('Ошибка');
          })
          .finally(function () {
            
          });

    },
    deleteDrop(){
      let student = {
        id: parseInt(this.studentId)
      }
      if(!confirm("Удалить выбытие?")){
        return;
      }
      let $this = this;
      ApiService.postSecured("students/deleteDrop", student)
          .then(function () {
            $this.reloadData();
          })
          .catch(() => {
            alert('Ошибка');
          })
          .finally(function () {

          });
    },
    deleteArrival(){
      let student = {
        id: parseInt(this.studentId)
      }
      if(!confirm("Удалить прибытие?")){
        return;
      }
      let $this = this;
      ApiService.postSecured("students/deleteArrival", student)
          .then(function () {
            $this.reloadData();
          })
          .catch(() => {
            alert('Ошибка');
          })
          .finally(function () {

          });
    },
    isAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
  }
};
</script>
<script setup>
</script>